import React, { Component } from "react";
import "../styles/style.css";
import circle from "../images/greyDot.png";
import circleRed from "../images/redDot.png";
import logo from "../images/logo.png";
import nameSlog from "../images/Group 1.png";
import PlayStore from "../images/playstore 1.png";
import Screen1 from "../images/PhoneScreens/HomeScreen.png";
import Screen2 from "../images/PhoneScreens/AllSkillHome.png";
import Screen3 from "../images/PhoneScreens/IndividualSkill.png";
import Screen4 from "../images/PhoneScreens/SavedUsers.png";
import Screen5 from "../images/PhoneScreens/CompareUsers.png";
import Screen6 from "../images/PhoneScreens/CompareBossKills.png";
import DropDown from "../images/dropDown.png";

let selectedButton = "btn1";

class Main extends Component {
  render() {
    const isMobile = window.innerWidth <= 500;
    if (isMobile) {
      return (
        <div class="MobScreen">
          <div class="FirstHalf">
            <div class="FirstContents">
              <div class="Logo2">
                <img class="Image2" src={logo} alt="logo" />
                <br></br>
                <img class="Name2" src={nameSlog} alt="logo" />
              </div>
              <div class="Description2">
                <p class="DescripTXT2">
                  View, save and compare users stats for the popular MMORPG
                  ‘OldSchool RuneScape’
                </p>
              </div>
              <div class="PlayStore2">
                <a href="https://play.google.com/store/apps/details?id=src.sample">
                  <img class="PlayStoreImg2" src={PlayStore} alt="" />
                </a>
              </div>
              <a class="Drop" href="#secondHalf">
                <img src={DropDown} alt="" />
              </a>
            </div>
          </div>
          <div class="SecondHalf">
            <div class="Phone2">
              <img
                class="mobileVector2"
                id="MobileVector"
                src={Screen1}
                alt=""
              />

              <div id="secondHalf" class="Circles2">
                <ul>
                  <li>
                    <img
                      id="btn1"
                      onClick={this.renderImageClicks}
                      src={circleRed}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn2"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn3"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn4"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn5"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn6"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div class="Screen">
          <div class="Box1">
            <div class="Phone">
              <img
                class="mobileVector"
                id="MobileVector"
                src={Screen1}
                alt=""
              />

              <div class="Circles">
                <ul>
                  <li>
                    <img
                      id="btn1"
                      onClick={this.renderImageClicks}
                      src={circleRed}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn2"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn3"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn4"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn5"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      id="btn6"
                      onClick={this.renderImageClicks}
                      src={circle}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="Box2">
            <div class="Logo">
              <img class="Image" src={logo} alt="logo" />
              <br></br>
              <img class="Name" src={nameSlog} alt="logo" />
            </div>
            <div class="Description">
              <p class="DescripTXT">
                View, save and compare users stats for the popular MMORPG
                ‘OldSchool RuneScape’
              </p>
            </div>
            <div class="PlayStore">
              <a href="https://play.google.com/store/apps/details?id=src.sample">
                <img class="PlayStoreImg" src={PlayStore} alt="" />
              </a>
            </div>
          </div>
        </div>
      );
    }
  }

  renderImageClicks(e) {
    const buttons = [
      document.getElementById("btn1"),
      document.getElementById("btn2"),
      document.getElementById("btn3"),
      document.getElementById("btn4"),
      document.getElementById("btn5"),
      document.getElementById("btn6"),
    ];

    const loc = document.getElementById("MobileVector");
    const images = [Screen1, Screen2, Screen3, Screen4, Screen5, Screen6];

    if (e.target.id === selectedButton) {
    } else {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].src === circleRed) {
          buttons[i].src = circle;
        }
      }
      selectedButton = e.target;
      e.target.setAttribute("src", circleRed);
      e.target.setAttribute("alt", "greyRed");
      e.target.setAttribute("style", "opacity:1;");
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].src === circleRed) {
          loc.src = images[i];
        }
      }
    }
  }
}
export default Main;
